import React from "react"
import Container from "../../components/layout/Container"
import Layout from "../../components/layout/Layout"
import WideContainer from "../../components/layout/WideContainer"
import MetaData from "../../components/seo/MetaData"
import OrganizationStructuredData from "../../components/seo/OrganizationStructuredData"

interface Props {
  location: any
}

export default function LightningPage({ location }: Props) {
  return (
    <Layout>
      <MetaData
        title="Information on lightning"
        description="Learn how to behave during lightning storms. This is important for your own safety in the wilderness."
        pageUrl={location.pathname}
      />
      <OrganizationStructuredData />
      <WideContainer center={true}>
        <div className={`container md:px-2 px-2`}>
          <h1 className="mt-6 md:mt-8 mb-8">Information on lightning</h1>
        </div>
      </WideContainer>
      <Container center={true}>
        <p>
          There are plenty of people unaware how to act when caught in a
          thunderstorm. This article is meant to provide you with some basic
          tips for staying safe during a thunderstorm. Be sure to check the
          weather before you enter a national park and avoid harsh weather in
          general.
        </p>
        <p>
          There are some basic advice to follow during lightning strikes. Try to
          avoid golf courses, water (puddles, lakes etc.) and high terrain.
        </p>
        <h3>If caught in a thunderstorm</h3>
        <h6>Descend to lower ground if you above the tree line</h6>
        <p>
          This makes you less of a target. You can run into a forest but try to
          avoid isolated trees.
        </p>
        <h6>Drop metal objects</h6>
        <p>
          Lightning are prone to striking golfers because of the raised clubs.
          Drop any golf club, umbrella or other metal object.
        </p>
        <h6>If you are caught in an open field, seek a low spot</h6>
        <p>
          Sometimes you will be unable to find shelter. Seek out the lowest spot
          in the open field and crouch down with your feet together. Do not sit
          down or lie down. This will create more contact with the ground.
        </p>
        <h6>Don't return to an open area too soon</h6>
        <p>
          Make sure you do not go back to an open area before the storm is
          completely over. Thunderstorms are just as dangerous in the end and
          some people have wrongly estimated the end of a storm.
        </p>
        <h6>Get off lakes or rivers and seek shelter</h6>
        <p>
          When a storm approaches, you need to get off any body of water and
          seek shelter. Make sure to continuously keep an eye out for incoming
          storms. Do not seek shelter within 100 metres from the shore of a
          lake.
        </p>
        <h3>If someone is struck by lightning</h3>
        <p>
          People struck by lightning do not carry electrical current. It is safe
          to tend to them. There is often a good chance to revive a person that
          appear dead. Begin CPR and mouth-to-mouth if the person is not
          breathing and there is no pulse. Stay with the victim until help
          arrives.
        </p>
        <h3>Lightning is unpredictable</h3>
        <p>
          Lightning can be very unpredictable and there are no guarantees. These
          basic tips do not substitute any medical treatment. They are only
          meant to help in case of emergency.
        </p>
      </Container>
    </Layout>
  )
}
